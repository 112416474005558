
import React, { useState, useEffect } from 'react';
import { useColorMode } from '@chakra-ui/react';
import Chart from "react-apexcharts";
import "./ApaxChartOverrides.css";

function SimpleFullTimeSeries({ activityAllData }) {
  const [graphConfig, setGraphConfig] = useState(null);
  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    if (activityAllData?.length > 0) {
      setGraphConfig({
        options: {
          theme: {
            mode: colorMode === 'light' ? 'lite' : 'dark',
            palette: 'palette4', 
          },
          chart: {
            type: 'line',
            height: 35,
            sparkline: {
              enabled: true
            }
          },
          stroke: {
            curve: 'smooth',
            width: 3
          },
          tooltip: {
            fixed: {
              enabled: false
            },
            x: {
              show: false
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return ''
                }
              }
            },
            marker: {
              show: false
            }
          }
        },
        series: [{
          data: activityAllData
        }],
      });
    }
  }, [activityAllData]);

  return (<>
    {graphConfig && <Chart
      options={graphConfig.options}
      series={graphConfig.series}
      type="line"
    />}
  </>);
}

export default React.memo(SimpleFullTimeSeries);
