import { useState } from 'react';
import { Text, Grid, GridItem, Button, useColorMode, Box, Flex, Container, ButtonGroup, Heading, Stack } from '@chakra-ui/react';
import { SunIcon, MoonIcon, HamburgerIcon } from '@chakra-ui/icons';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { AiFillHome } from 'react-icons/ai';
import './App.css';

import DCList from 'DCList';
import GamerPassport from 'DataCoalitions/GamerPassport';
import GamerPassportPreview from 'DataCoalitions/GamerPassport/Preview';
import CommunityMVP from 'DataCoalitions/CommunityMVP';

const GRID_LAYOUTS = {
  MENU_COLLAPSED: 1,
  MENU_EXTENDED: 2,
};

const gridLayouts = {
  1: `"header header"
  "main main"
  "footer footer"`,
  2: `"header header"
  "nav main"
  "nav footer"`,
};

function App() {
  const navigate = useNavigate();
  const [gridLayout, setGridLayout] = useState(GRID_LAYOUTS.MENU_COLLAPSED);
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Container maxW="container.xxl" h="100vh">
      <Grid
        templateAreas={gridLayouts[gridLayout]}
        gridTemplateRows={'80px 1fr 30px'}
        gridTemplateColumns={'100px 1fr'}
        h='100vh'
        gap='1'
        color=''
        fontWeight='bold'
      >
        <GridItem p="5" area={'header'}>
          <Flex minWidth='max-content' alignItems='center' gap='2'>
            <Box p='2'>
              <Heading size='md'>Data Coalitions Hub</Heading>
            </Box>
            <ButtonGroup gap='2'>
              <Button display={['none', 'initial']} onClick={toggleColorMode}>{colorMode === 'light' ? <MoonIcon /> : <SunIcon />}</Button>
              <Button display={['none', 'initial']} onClick={() => { navigate('/')}} ><AiFillHome /></Button>
              {/* <Button display={['none', 'initial']} onClick={() => {
                if (gridLayout === GRID_LAYOUTS.MENU_EXTENDED) {
                  setGridLayout(GRID_LAYOUTS.MENU_COLLAPSED);
                } else {
                  setGridLayout(GRID_LAYOUTS.MENU_EXTENDED);
                }
              }}><HamburgerIcon /></Button> */}
            </ButtonGroup>
          </Flex>
        </GridItem>
        {gridLayout === GRID_LAYOUTS.MENU_EXTENDED && <GridItem pl='2' bg='' area={'nav'}>
          <Flex direction="column" justify="space-between" minH="80vh" mt={2}>
            <Stack ml="15px" spacing={4}>
              <Button
                w={20}
                rightIcon={<AiFillHome />}
                colorScheme="teal"
                variant="solid"
                onClick={() => {
                  navigate('/');
                }}
              >
              </Button>
            </Stack>
          </Flex>
        </GridItem>}
        <GridItem pl='2' area={'main'}>
          <Box p="2">
            <Routes>
              <Route path="/" element={<DCList />} />
              <Route path="/gamer-passport" element={<GamerPassport />} />
              <Route path="/gamer-passport/preview" element={<GamerPassportPreview />} />
              <Route path="/community-mvp" element={<CommunityMVP />} />
            </Routes>
          </Box>
        </GridItem>
        <GridItem pl='2' area={'footer'}>
          <Text fontSize='sm'>An itheum.io Product. All rights reserved.</Text>
        </GridItem>
      </Grid>
    </Container>
  );
}

export default App;
