import React, { useEffect, useState } from 'react';
import { Text, HStack, Box, Spacer, Stack, Heading, Flex, useToast, useColorMode } from '@chakra-ui/react';
import GenericSimpleAreaTimeSeries from 'Charts/GenericSimpleAreaTimeSeries';
import GenericTimeSeries from 'Charts/GenericTimeSeries';
import ActivityHeatmap from 'Charts/ActivityHeatmap';
import ActivityFullTimeSeries from 'Charts/ActivityFullTimeSeries';
import SimpleFullTimeSeries from 'Charts/SimpleFullTimeSeries';

import myNFMe from 'img/my-nfme.png';
import myDataNFT from 'img/my-datanft-2.png';
import myClaimBG from 'img/reputation-claim-bg-1.png';
import myOAT1 from 'img/reputation-oat-1.png';
import myOAT2 from 'img/reputation-oat-2.png';
import myOAT3 from 'img/reputation-oat-3.png';


function GamerInsights({ gamerId, gamerData }) {
  const [userId, setUserId] = useState(null);
  const { colorMode, toggleColorMode } = useColorMode();

  const [readingsDiscordBotUserOnGuildActivity, setReadingsDiscordBotUserOnGuildActivity] = useState([]);
  const [readingsTrdPtyWonderHeroGameApi, setReadingsTrdPtyWonderHeroGameApi] = useState([]);
  const [readingsOnChainAddrTxOnCon, setReadingsOnChainAddrTxOnCon] = useState([]);
  const [readingsOnChainAddrTxOnConErd, setReadingsOnChainAddrTxOnConErd] = useState([]);
  const [gamingActivityAllData, setGamingActivityAllData] = useState([]);
  const [socialActivityAllData, setSocialActivityAllData] = useState([]);

  useEffect(() => {
    if (gamerData) {
      setUserId(gamerId);
      setReadingsOnChainAddrTxOnCon(gamerData.readingsOnChainAddrTxOnCon);
      setReadingsOnChainAddrTxOnConErd(gamerData.readingsOnChainAddrTxOnConErd);
      setReadingsDiscordBotUserOnGuildActivity(gamerData.readingsDiscordBotUserOnGuildActivity);
      setReadingsTrdPtyWonderHeroGameApi(gamerData.readingsTrdPtyWonderHeroGameApi);
      setSocialActivityAllData(gamerData.socialActivityAllData);
      setGamingActivityAllData(gamerData.gamingActivityAllData);
    }
  }, [gamerData]);

  useEffect(() => {
    console.log('colorMode', colorMode);
  }, [colorMode]);

  return (
    <>
      <Box>
        <HStack alignItems="flex-end">
          {/* <Box maxW='lg' borderWidth='1px' borderRadius='lg' minWidth="500px" minHeight="380px">
            <Stack p='6' spacing="4">
              <Heading size="lg" align="center" mb="10">Gamer Passport</Heading>

              <>
                <Text mb='8px'>User Id: {userId}</Text>
                <Input ref={inputUserIdRef} placeholder='enter userID' />
              </>

              <Box display="none">
                <Text mb='8px'>Program Id:</Text>
                <Input placeholder='02f2d3f0-cbaa-11ec-864d-c9ca0d926d97' disabled />
              </Box>

              <Button isLoading={dataFetching} colorScheme='teal' variant='solid' onClick={() => {
                fetchData(inputUserIdRef.current.value);
              }}>Fetch Data</Button>
            </Stack>
          </Box> */}

          {userId && <>
            <Box maxW="sm" borderWidth="1px" borderRadius="lg" minW="200px" minHeight="250px">
              <Stack p="4" h="300" bgImage={myNFMe} bgSize="cover" bgPosition="bottom" borderRadius="lg">
                <Heading size="md" align="center">NFMe ID Avatar</Heading>
              </Stack>
            </Box>

            <Box maxW="sm" borderWidth="1px" borderRadius="lg" w="200px">
              <Stack p="2" pt="4" h="300" borderRadius="lg">
                <Heading size="md" align="center">Soulbound Reputation</Heading>
                <Text fontSize="xs" align="center">Claims, OATs & Badges</Text>
                <Box h="190" overflow="scroll" overflowX="hidden" sx={{
                  '&::-webkit-scrollbar': {
                    width: '10px',
                    borderRadius: '2px',
                    backgroundColor: `#3f444e`,
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: `#46b3ad`,
                  },
                }}>
                  <Stack>
                    <Box h="190" bgImage={myClaimBG} bgSize="cover" bgPosition="bottom" align="center" display="flex" alignItems="center" justifyContent="center" borderRadius="lg" width="95%">
                      <Text color="white" fontSize="sm" width="50%">NFMe ID Mint Allowed</Text>
                    </Box>

                    <Box h="190" bgImage={myOAT1} bgSize="cover" bgPosition="bottom" align="center"  borderRadius="lg" width="95%"></Box>

                    <Box h="190" bgImage={myClaimBG} bgSize="cover" bgPosition="bottom" align="center" display="flex" alignItems="center" justifyContent="center"  borderRadius="lg" width="95%">
                      <Text color="white" fontSize="sm" width="50%">Gamer Passport Alpha Member</Text>
                    </Box>

                    <Box h="190" bgImage={myOAT2} bgSize="cover" bgPosition="bottom" align="center" borderRadius="lg" width="95%"></Box>

                    <Box h="190" bgImage={myOAT3} bgSize="cover" bgPosition="bottom" align="center" borderRadius="lg" width="95%"></Box>
                  </Stack>
                </Box>
              </Stack>
            </Box>

            <Box maxW="sm" borderWidth="1px" borderRadius="lg" w="180px" minHeight="250px">
              <Stack p="4" h="300" bgImage={myDataNFT} bgSize="130px" bgPosition="center" bgRepeat="no-repeat" borderRadius="lg">
                <Heading size="md" align="center">Data NFT License</Heading>
              </Stack>
            </Box>

            {gamingActivityAllData?.length > 0 && <Box maxW="sm" borderWidth="1px" borderRadius="lg" minW="400px" minHeight="250px">
              <Stack p="4" h="300">
                <Heading size="md" align="center" mb="10">Gaming Activity Overview</Heading>
                <Box>
                  <ActivityHeatmap startDate="2022-04-01" endDateIsToday={true} activityAllData={gamingActivityAllData} showToolTips={true} />
                </Box>
              </Stack>
            </Box>}

            {socialActivityAllData?.length > 0 && <Box maxW="sm" borderWidth="1px" borderRadius="lg" minW="300px" minHeight="250px">
              <Stack p="4" h="300">
                <Heading size="md" align="center" mb="10">Social Activity Overview</Heading>
                <Box>
                  <SimpleFullTimeSeries activityAllData={socialActivityAllData} />
                </Box>
              </Stack>
            </Box>}

          </>}
        </HStack>

        <Box p="2" pt="5" mt="5" pb="5" borderWidth='1px' borderRadius='lg'>
          {(readingsDiscordBotUserOnGuildActivity?.length === 0 &&
            readingsOnChainAddrTxOnCon.length === 0 &&
            readingsOnChainAddrTxOnConErd.length === 0 &&
            readingsTrdPtyWonderHeroGameApi?.length === 0 && gamingActivityAllData?.length === 0) && <Flex justifyContent="center" alignItems="center" minH="200px"><Text>No Data Yet...</Text></Flex> || <>

              <ActivityFullTimeSeries activityAllData={gamingActivityAllData} groupMergeMapping={{
                Polygon_Wonderhero: "Wonderhero",
                BSC_Wonderhero: "Wonderhero",
                Wonderhero_PVP: "Wonderhero",
                BSC_Cyball: "Cyball",
                Devnet_Knights_Of_Cathena: "Knights of Cathena"
              }} />

              <Spacer mt="10" />

              {(readingsOnChainAddrTxOnCon?.length > 0) &&
                <Box p="5"><GenericTimeSeries
                  title='On-Chain Activity (EVM Chains)'
                  data={readingsOnChainAddrTxOnCon}
                  scaleConfig={{
                    BSC_Other: {
                      lbl: 'BSC_Other',
                      clr: '#DDD'
                    },
                    BSC_Wonderhero: {
                      lbl: 'BSC_Wonderhero',
                      clr: 'salmon'
                    },
                    Polygon_Other: {
                      lbl: 'Polygon_Other',
                      clr: '#DDD'
                    },
                    Polygon_Wonderhero: {
                      lbl: 'Polygon_Wonderhero',
                      clr: '#06f308'
                    },
                    Unknown_Wonderhero: {
                      lbl: 'Unknown_Wonderhero',
                      clr: '#CCC'
                    },
                    BSC_Cyball: {
                      lbl: 'BSC_Cyball',
                      clr: '#76c3ff'
                    },
                    Unknown_Cyball: {
                      lbl: 'Unknown_Cyball',
                      clr: '#CCC'
                    },
                    unknown: {
                      lbl: 'unknown',
                      clr: 'red'
                    }
                  }}
                  lblMappings={{
                    'mainGroup': 'Blockchain and Game',
                    'data1': 'User',
                    'data2': 'From',
                    'data3': 'To',
                    'data4': 'Token Ticket',
                    'data5': 'Contract',
                    'data6': 'Transfer Type',
                    'data7': 'Trasnfer Val',
                    'data8': 'block Signed At',
                    'data9': 'TX Hash',
                    'data10': 'Identity Group',
                  }} /></Box>}

              <Spacer mt="10" />

              {(readingsOnChainAddrTxOnConErd?.length > 0) &&
                <Box p="5"><GenericTimeSeries
                  title='On-Chain Activity (Elrond Chain)'
                  data={readingsOnChainAddrTxOnConErd}
                  scaleConfig={{
                    Devnet_Other: {
                      lbl: 'Devnet_Other',
                      clr: '#CCC'
                    },
                    Mainnet_Other: {
                      lbl: 'Mainnet_Other',
                      clr: '#CCC'
                    },
                    Devnet_Knights_Of_Cathena: {
                      lbl: 'Devnet_Knights_Of_Cathena',
                      clr: '#debc6a'
                    },
                    unknown: {
                      lbl: 'unknown',
                      clr: 'red'
                    }
                  }}
                  lblMappings={{
                    'mainGroup': 'Blockchain and Game',
                    'data1': 'User',
                    'data2': 'From',
                    'data3': 'To',
                    'data4': 'Token Ticket',
                    'data5': 'Contract',
                    'data6': 'Transfer Type',
                    'data7': 'Trasnfer Val',
                    'data8': 'TX Hash',
                    'data9': 'Identity Group',
                  }} /></Box>}

              <Spacer mt="10" />

              {(readingsTrdPtyWonderHeroGameApi?.length > 0) &&
                <Box p="5"><GenericTimeSeries
                  title='WonderHero Private API - PVP Performance'
                  data={readingsTrdPtyWonderHeroGameApi}
                  scaleConfig={{
                    Wonderhero_PVP: {
                      lbl: 'Wonderhero_PVP',
                      clr: 'lightblue'
                    }
                  }}
                  lblMappings={{
                    'mainGroup': 'Datapoint Type',
                    'data1': 'User',
                    'data2': 'Season',
                    'data3': 'Rank',
                    'data4': 'Score',
                    'data5': 'Level',
                    'data6': 'Belt',
                    'data7': 'Times',
                    'data8': 'Attack WinRate',
                    'data9': 'Defense WinRate',
                    'data10': 'Win Streak',
                    'data11': 'Identity Group',
                  }} /></Box>}

              <Spacer mt="10" />

              {(readingsDiscordBotUserOnGuildActivity?.length > 0) &&
                <Box p="5"><GenericSimpleAreaTimeSeries
                  title='Discord Community Activity'
                  data={readingsDiscordBotUserOnGuildActivity}
                  dataKey='val'
                  color='#78ad69'
                  legend={[]}
                  lblMappings={{
                    'mainVal': 'Community Engagement Score',
                    'data1': 'Discord ID',
                    'data2': 'Guild ID',
                    'data3': 'Message Count',
                    'data4': 'Reaction Count',
                    'data5': 'Reply Count',
                    'data6': 'Messaging Frequency',
                    'data7': 'Mentioned Count',
                    'data8': 'Message Length',
                    'data9': 'Streaming Stats',
                    'data10': 'Streaming Score',
                    'data11': 'Last Active',
                  }} /></Box>}

              <Spacer mt="10" />
            </>}
        </Box>
      </Box>
    </>
  );
}

export default React.memo(GamerInsights);
