
import React, { useState, useEffect } from 'react';
import { useColorMode } from '@chakra-ui/react';
import Chart from 'react-apexcharts';
import { getTicks, getDatesInRange, seriesDataForGroup } from 'lib/util';
import './ApaxChartOverrides.css';

function ActivityFullTimeSeries({ activityAllData, groupMergeMapping }) {
  const [graphConfig, setGraphConfig] = useState(null);
  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    if (activityAllData?.length > 0) {
      const uniqueEventTicks = getTicks(activityAllData);

      const d1 = new Date(uniqueEventTicks[0]);
      const d2 = new Date(uniqueEventTicks[uniqueEventTicks.length - 1]);

      // get an array of unique date strings from 1st date of datapoint to the last date of datapoint
      const [, unqDateStrs] = getDatesInRange(d1, d2);

      // pass in all gaming activity and get series data per day of unqDateStrs
      const seriesDataList = seriesDataForGroup(activityAllData, unqDateStrs, groupMergeMapping);

      // (area = ) https://apexcharts.com/javascript-chart-demos/area-charts/irregular-timeseries/
      setGraphConfig({
        options: {
          theme: {
            mode: colorMode === 'light' ? 'lite' : 'dark',
            palette: 'palette4',
          },
          chart: {
            height: 328,
            type: 'area',
            stacked: false, // for area only
            // type: 'line',
            zoom: {
              enabled: false
            },
            dropShadow: {
              enabled: false,
              top: 3,
              left: 2,
              blur: 4,
              opacity: 1,
            }
          },
          stroke: {
            curve: 'smooth',
            width: 2
          },
          title: {
            text: 'Historic Gaming Activity',
            align: 'left',
            offsetY: 25,
            offsetX: 20
          },
          subtitle: {
            text: 'Games',
            offsetY: 55,
            offsetX: 20
          },
          markers: {
            size: 6,
            strokeWidth: 0,
            hover: {
              size: 9
            }
          },
          grid: {
            show: true,
            padding: {
              bottom: 0
            }
          },
          labels: unqDateStrs,
          xaxis: {
            tooltip: {
              enabled: false,
            }
          },
          legend: {
            position: 'top',
            horizontalAlign: 'right',
            offsetY: -20
          },
          fill: { // area only
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.45,
              opacityTo: 0.05,
              stops: [20, 100, 100, 100]
            },
          },
        },
        series: seriesDataList,
      });
    }
  }, [activityAllData]);

  // console.log('=== ActivityFullTimeSeries ', activityAllData);
  return (<>
    {graphConfig && <Chart
      options={graphConfig.options}
      series={graphConfig.series}
      type="area"
      height="350"
    />}
  </>);
}

export default React.memo(ActivityFullTimeSeries);
