import React, { Component } from 'react';
import moment from 'moment';
import { veryFirstTimeStampsPerMonth, isBrowser } from 'lib/util';
import { ResponsiveContainer, AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip, ReferenceLine, Legend } from 'recharts';

class GenericSimpleAreaTimeSeries extends Component {
  state = {
    debugMode: (isBrowser() && window.location.search.indexOf('debug=1') > -1) ? 1 : 0
  };

	componentDidMount() {}

	render() {
		const { lblMappings } = this.props;

		const getTicks = (data) => {
			return veryFirstTimeStampsPerMonth(data);
		};

		const toMonthBlock = (when) => {
			return `${moment(when).format('DD MMM YY')}`;
		};

		const ifObjectMakeString = val => {
			if (typeof val === 'object') {
				return JSON.stringify(val);
			} else {
				return val;
			}			
		};

		const renderTooltipContent = (o) => {
			const { payload } = o;

			if (payload && payload.length && payload[0].payload && payload[0].payload.data) {
				
				const { when, val } = payload[0].payload;

        if (this.state.debugMode) {
          console.group();
          console.log(when);
          console.log(`${lblMappings['mainVal']}: ${val}`);
          console.log(payload[0].payload.data);
          console.groupEnd();
        }

				const { data1, data2, data3, data4, data5, data6, data7, data8, data9, data10, data11 } = payload[0].payload.data;

				return (
					<div style={{ backgroundColor: 'black', color: 'white', padding: '3px', fontSize: '.6rem' }}>
						{
							payload && payload.length && <div>
								<div>
									<div><span>{when}</span></div>
									<div><span>{lblMappings['mainVal']}: </span><span>{val}</span></div>
								</div>
								<div>
									{data2 && <div><span>{lblMappings['data1']}: </span><span>{ifObjectMakeString(data1)}</span></div>}
									{data2 && <div><span>{lblMappings['data2']}: </span><span>{ifObjectMakeString(data2)}</span></div>}
									{data3 && <div><span>{lblMappings['data3']}: </span><span>{ifObjectMakeString(data3)}</span></div>}
									{data4 && <div><span>{lblMappings['data4']}: </span><span>{ifObjectMakeString(data4)}</span></div>}
									{data5 && <div><span>{lblMappings['data5']}: </span><span>{ifObjectMakeString(data5)}</span></div>}
									{data6 && <div><span>{lblMappings['data6']}: </span><span>{ifObjectMakeString(data6)}</span></div>}
									{data7 && <div><span>{lblMappings['data7']}: </span><span>{ifObjectMakeString(data7)}</span></div>}
									{data8 && <div><span>{lblMappings['data8']}: </span><span>{ifObjectMakeString(data8)}</span></div>}
									{data9 && <div><span>{lblMappings['data9']}: </span><span>{ifObjectMakeString(data9)}</span></div>}
									{data10 && <div><span>{lblMappings['data10']}: </span><span>{ifObjectMakeString(data10)}</span></div>}
									{data11 && <div><span>{lblMappings['data11']}: </span><span>{ifObjectMakeString(data11)}</span></div>}
								</div>
							</div>
						}
					</div>
				);
			} else {
				return <div></div>;
			}
		};

		return(
			<div>
				<h2>{this.props.title}</h2>
				{
					(this.props.data.length === 0)
					?
						<div>No data to display, please try a different Time Block.</div>
					:
						<ResponsiveContainer width="100%" height={400}>
							<AreaChart data={this.props.data} margin={{ top: 20, right: 0, left: 15, bottom: 0 }} syncId="anyId">
                <defs>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                  </linearGradient>
                </defs>
								<XAxis dataKey="when" ticks={getTicks(this.props.data)} tickFormatter={toMonthBlock} minTickGap={25} tick="black" padding={{ right: 40 }} />
                
                {/* https://recharts.org/en-US/api/YAxis#scale */}
								<YAxis dataKey={this.props.dataKey} tick="black" tickSize={20} scale="sqrt" padding={{ top: 20, bottom: 20 }} domain={['dataMin', 'auto']} />
								
                <CartesianGrid strokeDasharray="2 2" opacity={.8} />

								<Tooltip content={renderTooltipContent}/>

								<Area type='monotone' dataKey={this.props.dataKey} stroke={this.props.color} fillOpacity={1} fill="url(#colorPv)" dot={{  stroke: 'green', strokeWidth: 5, fill: 'green' }} />
								
                {
									(this.props.legend.length > 0)
									?
										<ReferenceLine y={60} label="" stroke="green" strokeDasharray="3 3" />
									:
										null
								}
								<Legend payload={this.props.legend} />
							</AreaChart>
						</ResponsiveContainer>
				}
			</div>
		);
	}
}

export default GenericSimpleAreaTimeSeries;
