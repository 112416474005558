
import React, { Component } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

class GenericCustomShapeBarChart extends Component {
  render() {
    return (
      <div>
        <h2>{`${this.props.title || ''}`}</h2>
        {
          (this.props.data?.length === 0)
            ?
            <div>No data to display, please try a different Time Block.</div>
            :
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={this.props.data}
                margin={{
                  top: 20,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="reactionCount" stackId="a" fill="#8884d8" />
                <Bar dataKey="messageCount" stackId="a" fill="#82ca9d" />
                <Bar dataKey="replyCount" fill="#ffc658" />
                <Bar dataKey="mentionedCount" fill="#bbc658" />
              </BarChart>
            </ResponsiveContainer>
        }
      </div>
    );
  }
}

export default GenericCustomShapeBarChart;
