import { Text, Button, Box, Flex, ButtonGroup, Heading, Divider } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

function DCList() {
  const navigate = useNavigate();

  return (
    <>
      <Flex wrap="wrap" spacing={5}>
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" maxW="sm" mr="1rem" mb="1rem">
          <Flex direction="column" justify="space-between" height="100%">
            <Box>
              <Heading size="md" p="5">Gamer Passport</Heading>
            </Box>

            <Divider />

            <Box p="3">
              <Text>On-chain and Off-chain data sourced direct from gamers</Text>
            </Box>

            <Box p="3">
              <ButtonGroup colorScheme="teal" spacing="3" size="sm">
                <Button colorScheme="teal" onClick={() => {
                  navigate("gamer-passport/preview");
                }}>Preview Data</Button>
                <Button disabled={true} colorScheme="teal" variant="outline">Buy Full Data Access</Button>
              </ButtonGroup>
            </Box>
          </Flex>
        </Box>

        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" maxW="sm" mr="1rem" mb="1rem">
          <Flex direction="column" justify="space-between" height="100%">
            <Box>
              <Heading size="md" p="5">Community MVP</Heading>
            </Box>

            <Divider />

            <Box p="3">
              <Text>Find the highest value community members</Text>
            </Box>

            <Box p="3">
              <ButtonGroup colorScheme="teal" spacing="3" size="sm">
                <Button colorScheme="teal" onClick={() => {
                  navigate("community-mvp");
                }}>Preview Data</Button>
                <Button disabled={true} colorScheme="teal" variant="outline">Buy Full Data Access</Button>
              </ButtonGroup>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
}

export default DCList;
