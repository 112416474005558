
import React, {Component, PureComponent} from 'react';
import { ResponsiveContainer, Brush, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { isBrowser } from 'lib/util';
import moment from 'moment-timezone';

let fixed = false;

const getTicks = (data) => {
	if (!data || !data.length ) {return [];}

	const firstReadingMs = data[0].time;
	
	// for some reason, render is called multiple times and somehow data is mutating
	// ... so we calculate dayBeforeFirstReadingMs only once (if not we keep going back one day for each render here)
	let dayBeforeFirstReadingMs = firstReadingMs;
	if (!fixed) {
		dayBeforeFirstReadingMs = firstReadingMs - 24*60*60*1000;
		fixed = true;
	}

	const dayBeforeFirstReading = new Date(dayBeforeFirstReadingMs);
	const nowInMs = new Date();

  // const domain = [new Date(data[0].time), new Date(data[data.length - 1].time)];
  const domain = [dayBeforeFirstReading, nowInMs];
	const scale = window && window.d3_scale.scaleTime().domain(domain).range([0, 1]);
  const ticks = window && window.d3_time && scale.ticks(window.d3_time.timeDay, 1);
  
  return ticks.map(entry => +entry);
};

const getTicksData = (data, ticks) => {
	if (!data || !data.length ) {return [];}
  const dataMap = new Map(data.map((i) => [i.time, i]));
	
	ticks.forEach(function (item, index, array) {
		if(!dataMap.has(item)) {
			data.push({time: item});
		}
	});
	
	return data;
};

const dateFormat = (time) => {
	// return moment(time).tz('Asia/Dubai').format('DD/MM');
	return moment(time).format('DD/MM');
};

class CustomizedAxisTick extends PureComponent {
  render() {
    const {
      x, y, payload,
    } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" transform="rotate(-45)">{moment(payload.value).format('DD/MM')}</text>
      </g>
    );
  }
}

class CustomizedDot extends Component{
  render () {
		const {cx, cy, payload, scaleConfig} = this.props;
		const itemConfig = Object.keys(scaleConfig).find(i => scaleConfig[i].lbl === payload.group);
		
		if (itemConfig) {
			return (
        <svg x={cx - 5} y={cy - 5} width={25} height={25} viewBox="0 0 1024 1024">
					<circle cx="250" cy="250" r="250" stroke="black" strokeWidth="10" fill={scaleConfig[itemConfig].clr} />
        </svg>
      );
		} else {
			return null;
		}
  }
};

class GenericTimeSeries extends Component {
	state = {
		fullTimeSeriesView: 0,
    debugMode: (isBrowser() && window.location.search.indexOf('debug=1') > -1) ? 1 : 0
	};

	render() {
		const {scaleConfig, lblMappings} = this.props;

    // sort by oldest readings to newest readings
    const sortedData = this.props.data.sort(function(a, b) {
			return a.time - b.time;
		});

		const ticksArr = getTicks(sortedData);
    const completeData = getTicksData(sortedData, ticksArr);
		
    const completeSortedData = completeData.sort(function(a, b) {
			return a.time - b.time;
		});

		const renderTooltipContent = (o) => {
			const { payload } = o;

			if (payload && payload.length && payload[0].payload && payload[0].payload.data) {
				
				const {when, group} = payload[0].payload;

        if (this.state.debugMode) {
          console.group();
          console.log(when);
          console.log(payload[0].payload.data);
          console.groupEnd();
        }

				const {data1, data2, data3, data4, data5, data6, data7, data8, data9, data10, data11} = payload[0].payload.data;

				return (
					<div style={{backgroundColor: "black", color: "white", padding: "3px", fontSize: ".6rem"}}>
						{
							payload && payload.length && <div>
								<div>
									<div><span>{when}</span></div>
									<div><span>{lblMappings['mainGroup']}: </span><span>{group}</span></div>
								</div>
								<div>
									{data1 !== undefined && <div><span>{lblMappings['data1']}: </span><span>{data1}</span></div>}
									{data2 !== undefined && <div><span>{lblMappings['data2']}: </span><span>{data2}</span></div>}
									{data3 !== undefined && <div><span>{lblMappings['data3']}: </span><span>{data3}</span></div>}
									{data4 !== undefined && <div><span>{lblMappings['data4']}: </span><span>{data4}</span></div>}
									{data5 !== undefined && <div><span>{lblMappings['data5']}: </span><span>{data5}</span></div>}
									{data6 !== undefined && <div><span>{lblMappings['data6']}: </span><span>{data6}</span></div>}
									{data7 !== undefined && <div><span>{lblMappings['data7']}: </span><span>{data7}</span></div>}
									{data8 !== undefined && <div><span>{lblMappings['data8']}: </span><span>{data8}</span></div>}
									{data9 !== undefined && <div><span>{lblMappings['data9']}: </span><span>{data9}</span></div>}
									{data10 !== undefined && <div><span>{lblMappings['data10']}: </span><span>{data10}</span></div>}
									{data11 !== undefined && <div><span>{lblMappings['data11']}: </span><span>{data11}</span></div>}
								</div>
							</div>
						}
					</div>
				);
			} else {
				return <div></div>;
			}
		};

		const widthPerTick = 50;
		let totalWidthForTicks = ticksArr.length * widthPerTick;

		if (!this.state.fullTimeSeriesView) {
			totalWidthForTicks = '100%';
		}

		const removeUnderScores = val => {
			return val.replace('_', ' ');
		};

		const legend = Object.keys(scaleConfig).map((v, idx) => {
			return { value: removeUnderScores(scaleConfig[v].lbl), type: 'circle', id: `ID${idx}`, color: scaleConfig[v].clr };
		});

		return (
			<div>
				<h2>{`${this.props.title}`}</h2>
				{
					(this.props.data.length === 0)
					?
						<div>No data to display, please try a different Time Block.</div>
					:
						<div>
							<div >
								<ResponsiveContainer width={totalWidthForTicks} height={300}>
									<LineChart data={completeSortedData} margin={{top: 15, right: 5, left: -20, bottom: 5}}>
                    <Brush dataKey='when' height={50} stroke={this.state.fullTimeSeriesView ? '#000' : '#000'} />
                    <XAxis dataKey="time" ticks={ticksArr} tickCount={ticksArr.length} tickFormatter={dateFormat} tick={<CustomizedAxisTick />} />
                    <YAxis dataKey="val" padding={{ bottom: 100, top: 0 }} tickCount={2} tick={false} />
                    <CartesianGrid />
                    <Tooltip content={renderTooltipContent}/>
                    <Line type="monotone" dataKey="pv" dot={{strokeWidth: 0}}/>
                    <Line type="monotone" dataKey="val" stroke="#8884d8" dot={<CustomizedDot scaleConfig={scaleConfig} />}/>
                    <Legend payload={legend} />
									</LineChart>
								</ResponsiveContainer>
							</div>
						</div>
				}
			</div>
		);
	}
}

export default GenericTimeSeries;
