import React, { useEffect, useState, useRef } from 'react';
import { Box, CircularProgress, Flex, useToast, useColorMode } from '@chakra-ui/react';
import { onChainDataInsights_LIB, thirdPartyDataInsights_LIB } from 'lib/core';
import { sleep } from 'lib/util';
import GamerInsights from './GamerInsights';

let pageInitDone = false;

function GamerPassport() {
  const [userId, setUserId] = useState(null);
  const [programId, setProgramId] = useState('02f2d3f0-cbaa-11ec-864d-c9ca0d926d97');
  const [dataFetching, setDataFetching] = useState(false);
  const toast = useToast();
  const { colorMode, toggleColorMode } = useColorMode();
  const inputUserIdRef = useRef();

  const [activeGamerData, setActiveGamerData] = useState(null);

  useEffect(() => {
    if (!pageInitDone) {
      initPageLoad();
      pageInitDone = true;
    }
  }, []);

  useEffect(() => {
    console.log('colorMode', colorMode);
  }, [colorMode]);

  const initPageLoad = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const userId = queryParams.get('userId');

    if (userId && userId.length > 8) {
      await sleep(1);
      fetchData(userId);
    }
  };

  const fetchData = async (_userId) => {
    if (_userId) {
      setUserId(_userId);
      setActiveGamerData(null);
      setDataFetching(true);

      const myHeaders = new Headers();
      myHeaders.append('authorization', '30f32d40-1511-11e7-a972-61c32d1d9c2e');

      const requestOptions = {
        method: 'GET',
        headers: myHeaders
      };

      let res;
      let actualData;

      try {
        res = await fetch(`https://itheumapi.com/readingsStream/${_userId}/${programId}`, requestOptions);
        actualData = await res.json();

        console.log(actualData);

        if (actualData.success === false) {
          toast({
            title: JSON.stringify(actualData.error),
            status: 'error',
            isClosable: true,
          });

          setDataFetching(false);
          return;
        }

      } catch (e) {
        toast({
          title: e.toString(),
          status: 'error',
          isClosable: true,
        });
      }

      fixData(actualData);
    }
  }

  const fixData = (rawData) => {
    if (rawData.items.length > 0) {
      const readingsInGroups = rawData.metaData.getDataConfig.dataToGather.allApplicableDataTypes.reduce((t, i) => {
        t[i.toString()] = [];
        return t;
      }, {});

      rawData.items.forEach(i => {
        readingsInGroups[i.dataType].push(i);
      });

      const gamingActivityAll = [];
      const socialActivityAll = [];

      const onChainManualDataSets = {
        onChainAddrTxOnCon: [],
        onChainAddrTxOnConErd: []
      };

      const thirdPartyManualDataSets = {
        discordBotUserOnGuildActivity: [],
        trdPtyWonderHeroGameApi: [],
      };

      Object.keys(readingsInGroups).forEach(dataType => {
        switch (dataType) {
          case '4': {
            if (readingsInGroups['4'].length > 0) {
              const programOnChainReadingsWithInsights = onChainDataInsights_LIB({
                rawReadings: readingsInGroups['4'],
                userTz: ''
              });

              const readingsWithInsights = programOnChainReadingsWithInsights.readings;
              
              // S: Time Data graphs
              for (let i = 0; i < readingsWithInsights.length; i++) {
                if (readingsWithInsights[i].manual === 'OnChainAddrTxOnCon') {
                  const item = {
                    group: readingsWithInsights[i].scoreGroup,
                    time: readingsWithInsights[i].time,
                    when: readingsWithInsights[i].friendyCreatedAt,
                    val: 0,
                    data: readingsWithInsights[i].data
                  };

                  onChainManualDataSets.onChainAddrTxOnCon.push(item);
                  gamingActivityAll.push(item);
                }
                else if (readingsWithInsights[i].manual === 'OnChainAddrTxOnConErd') {
                  const item = {
                    group: readingsWithInsights[i].scoreGroup,
                    time: readingsWithInsights[i].time,
                    when: readingsWithInsights[i].friendyCreatedAt,
                    val: 0,
                    data: readingsWithInsights[i].data
                  };

                  onChainManualDataSets.onChainAddrTxOnConErd.push(item);
                  gamingActivityAll.push(item);
                }
              }
              // E: Time Data graphs
            }
          }

            break;

          case '5': {
            if (readingsInGroups['5'].length > 0) {
              const thirdPartyReadingsWithInsights = thirdPartyDataInsights_LIB({
                rawReadings: readingsInGroups['5'],
                userTz: ''
              });

              const readingsWithInsights = thirdPartyReadingsWithInsights.readings;
              
              // S: Time Data graphs
              for (let i = 0; i < readingsWithInsights.length; i++) {
                if (readingsWithInsights[i].manual === 'DiscordBotUserOnGuildActivity') {
                  thirdPartyManualDataSets.discordBotUserOnGuildActivity.push(
                    {
                      // group: parseInt(readingsWithInsights[i].val, 10),
                      when: readingsWithInsights[i].friendyCreatedAt,
                      data: readingsWithInsights[i].data,
                      val: parseInt(readingsWithInsights[i].val, 10)
                    }
                  );

                  socialActivityAll.push(parseInt(readingsWithInsights[i].val, 10));

                } else if (readingsWithInsights[i].manual === 'TrdPtyWonderHeroGameApi') {
                  const item = {
                    group: readingsWithInsights[i].scoreGroup,
                    time: readingsWithInsights[i].time,
                    when: readingsWithInsights[i].friendyCreatedAt,
                    val: 0,
                    data: readingsWithInsights[i].data
                  };

                  thirdPartyManualDataSets.trdPtyWonderHeroGameApi.push(item);
                  gamingActivityAll.push(item);
                }
              }
              // E: Time Data graphs              
            }
          }

            break;
        }
      });

      setActiveGamerData({
        readingsOnChainAddrTxOnCon: onChainManualDataSets.onChainAddrTxOnCon,
        readingsOnChainAddrTxOnConErd: onChainManualDataSets.onChainAddrTxOnConErd,
        readingsDiscordBotUserOnGuildActivity: thirdPartyManualDataSets.discordBotUserOnGuildActivity,
        readingsTrdPtyWonderHeroGameApi: thirdPartyManualDataSets.trdPtyWonderHeroGameApi,
        socialActivityAllData: socialActivityAll,
        gamingActivityAllData: gamingActivityAll,
      });

    }

    setDataFetching(false);
  }

  return (
    <>
      <Box>
        {dataFetching && <Flex justifyContent="center" alignItems="center" minH="200px"><CircularProgress isIndeterminate color='green.300' /></Flex> ||
        <GamerInsights gamerId={userId} gamerData={activeGamerData} />}
      </Box>
    </>
  );
}

export default GamerPassport;
