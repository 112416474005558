import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import {
  Text, Button, CircularProgress, HStack, Box, Input, Flex, Stack, useToast,
  Table, Thead, Tr, Th, Tbody, Td, Tfoot
} from '@chakra-ui/react';
import { sleep } from 'lib/util';
import GenericCustomShapeBarChart from 'Charts/GenericCustomShapeBarChart';

let pageInitDone = false;

function CommunityMVP() {
  const [guildId, setGuildId] = useState('869901313616527360');
  const [dataFetching, setDataFetching] = useState(false);
  const toast = useToast();

  const defaultGuildUserInsights = { tableData: [], barChartData: [], otherInsights: null };

  const [guildUserInsights, setGuildUserInsights] = useState(defaultGuildUserInsights);

  useEffect(() => {
    if (!pageInitDone) {
      initPageLoad();
      pageInitDone = true;
    }
  }, []);

  const initPageLoad = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const guildId = queryParams.get("guildId");

    if (guildId && guildId.length > 8) {
      setGuildId(guildId);
      await sleep(1);
      fetchData(guildId);
    }
  };

  const fetchData = async (_guildId) => {
    if (guildId || _guildId) {
      setGuildUserInsights(defaultGuildUserInsights);
      setDataFetching(true);

      const myHeaders = new Headers();
      myHeaders.append("authorization", '30f32d40-1511-11e7-a972-61c32d1d9c2e');

      const requestOptions = {
        method: 'GET',
        headers: myHeaders
      };

      let res;
      let actualData;

      try {
        res = await fetch(`https://itheumapi.com/bespoke/3rdparty/CommunityInsights/?guildId=${guildId || _guildId}`, requestOptions);
        console.log(res);
        actualData = await res.json();

        console.log(actualData);

        if (actualData.success === false) {
          toast({
            title: JSON.stringify(actualData.error),
            status: 'error',
            isClosable: true,
          });

          setDataFetching(false);
          return;
        }

      } catch (e) {
        toast({
          title: e.toString(),
          status: 'error',
          isClosable: true,
        });
      }

      fixData(actualData);
    }
  }

  const fixData = (rawData) => {
    if (rawData.length > 0) {
      const fixedData = rawData.sort((a, b) => {
        return b.activityScore - a.activityScore;
      });

      let barChartData = fixedData.map(i => ({
        name: i.name,
        reactionCount: i.reactionCount,
        messageCount: i.messageCount,
        replyCount: i.replyCount,
        mentionedCount: i.mentionedCount,
      }));

      barChartData = barChartData.slice(0, 10); // only top 10 needed

      // S: other insights
      const activitiesSortedByRecent = fixedData.map(i => i.updatedAt).sort().reverse();
      // E: other insights

      setGuildUserInsights({
        tableData: fixedData,
        barChartData,
        otherInsights: {
          activitiesSortedByRecent
        }
      });
    }

    setDataFetching(false);
  }

  return (
    <>
      <Box>
        <HStack alignItems={"baseline"}>
          <Box maxW='lg' borderWidth='1px' borderRadius='lg' minWidth="500px">
            <Stack p='6' spacing="4">
              <>
                <Text mb='8px'>Guild Id: {guildId}</Text>
                <Input onChange={(e) => setGuildId(e.target.value)} placeholder='enter guildId' />
              </>

              <Button isLoading={dataFetching} colorScheme='teal' variant='solid' onClick={() => fetchData()}>Get Data</Button>
            </Stack>
          </Box>

          {guildUserInsights.otherInsights && <Box maxW='lg' borderWidth='1px' borderRadius='lg' minWidth="400px">
            <Stack p='6' spacing="4">
              <>
                <Text>Latest Activity: {`${moment(guildUserInsights.otherInsights.activitiesSortedByRecent[0]).fromNow()}`}</Text>
                <Text>Oldest Activity: {`${moment(guildUserInsights.otherInsights.activitiesSortedByRecent[guildUserInsights.otherInsights.activitiesSortedByRecent.length - 1]).fromNow()}`}</Text>
                <Text>Total monitored users: {guildUserInsights.tableData.length}</Text>
              </>
            </Stack>
          </Box>}
        </HStack>

        <Box p="2" pt="5" mt="5" pb="5" borderWidth='1px' borderRadius='lg'>
          {dataFetching && <CircularProgress isIndeterminate color='green.300' /> || <>
            {guildUserInsights.tableData.length === 0 && <Text>No Data Yet...</Text> || <>
              <Flex spacing={5}>  
                {(guildUserInsights.tableData.length > 0) &&
                  <Box>
                    <Table variant="striped" mt="3" size="sm">
                      <Thead>
                        <Tr>
                          <Th>Rank</Th>
                          <Th>Score</Th>
                          <Th>last Active</Th>
                          <Th>UserId</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {guildUserInsights.tableData.map((item, idx) => <Tr key={item.updatedAt}>
                          <Td>#{++idx}</Td>
                          <Td>{item.activityScore}</Td>
                          <Td>{`${moment(item.updatedAt).fromNow()}`}</Td>
                          <Td>{item.userId}</Td>
                        </Tr>)}
                      </Tbody>
                      <Tfoot>
                        <Tr>
                          <Th>Rank</Th>
                          <Th>Score</Th>
                          <Th>last Active</Th>
                          <Th>UserId</Th>
                        </Tr>
                      </Tfoot>
                    </Table>
                  </Box>}

                {(guildUserInsights.barChartData.length > 0) && <Box width="90%">
                  <GenericCustomShapeBarChart title="Top 10 members' activity split" data={guildUserInsights.barChartData} />
                </Box>}
              </Flex>
            </>}
          </>}
        </Box>
      </Box>
    </>
  );
}

export default CommunityMVP;
