import React, { useEffect, useState } from 'react';
import {
  Wrap, WrapItem, Flex, Text, Stack, Skeleton, Heading, Tag, Button, HStack,
  Box, IconButton, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, DrawerCloseButton,
  useToast, useColorMode, useDisclosure
} from '@chakra-ui/react';
import { ViewIcon } from '@chakra-ui/icons';
import { gamerPassportNormaliseUserDataSet } from 'lib/util';
import ActivityHeatmap from 'Charts/ActivityHeatmap';
import GamerInsights from './GamerInsights';

let pageInitDone = false;

function GamerPassportPreview() {
  const [fullDataSet, setFullDataSet] = useState(null);
  const [dataset, setDataSet] = useState(null);
  const [dataFetching, setDataFetching] = useState(false);
  const toast = useToast();
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeUser, setActiveUser] = useState(null);
  const [activeGamerData, setActiveGamerData] = useState(null);
  const [dataSummary, setDataSummary] = useState({ totalDataPoints: 0, totalUsers: 0 });
  const [activeFilterCode, setActiveFilterCode] = useState('all');

  useEffect(() => {
    if (!pageInitDone) {
      pageInitDone = true;
      fetchData();
    }
  }, []);

  useEffect(() => {
    console.log('colorMode', colorMode);
  }, [colorMode]);

  const fetchData = async () => {
    setDataSet([]);
    setDataFetching(true);

    const myHeaders = new Headers();
    myHeaders.append('authorization', '30f32d40-1511-11e7-a972-61c32d1d9c2e');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders
    };

    let res;
    let actualData;

    try {
      res = await fetch('https://itheumapi.com/ddex/dataMarshal/readingsStreamDC/1', requestOptions);
      actualData = await res.json();
    } catch (e) {
      toast({
        title: e.toString(),
        status: 'error',
        isClosable: true,
      });
    }

    fixData(actualData);
  }

  const filterData = (filterOptions) => {
    const { filterCode } = filterOptions;
    const filteredData = { ...fullDataSet };
    const usersWithNoTargetData = [];

    if (filterCode === 'elrond') {
      // find users with no elrond data
      Object.keys(filteredData).map(userId => {
        if(filteredData[userId].readingsOnChainAddrTxOnConErd?.length === 0) {
          usersWithNoTargetData.push(userId);
        }
      });
    } else if (filterCode === 'evm') {
      // find users with no elrond data
      Object.keys(filteredData).map(userId => {
        if (filteredData[userId].readingsOnChainAddrTxOnCon?.length === 0) {
          usersWithNoTargetData.push(userId);
        }
      });
    }

     // filter all these users
     usersWithNoTargetData.forEach(userId => {
      delete filteredData[userId];
    });

    console.log('$ ************************');
    console.log(filteredData);
    console.log('$ ************************');

    setActiveFilterCode(filterCode);
    setDataSet(filteredData);
  }

  const fixData = (rawData) => {

    console.log('************************');
    console.log(rawData);
    console.log('************************');

    const userFixedDataSetMap = {};

    let totalDataPoints = 0;

    rawData.map(userDateSet => {
      userFixedDataSetMap[userDateSet.userId] = gamerPassportNormaliseUserDataSet(userDateSet.data);

      console.log(userDateSet.data.metaData.count);
      totalDataPoints += userDateSet.data.metaData.count;
    });

    // S: lets sort the userFixedDataSetMap to put users without data at the end
    // A) find users with no data at all
    let usersWithNoDataAtAllList = Object.keys(userFixedDataSetMap).filter(i => (Object.keys(userFixedDataSetMap[i]).length === 0));

    // B) find users with no gaming data
    const usersWithNoGamingDataMap = {};
    
    Object.keys(userFixedDataSetMap).forEach(i => {
      if (userFixedDataSetMap[i].gamingActivityAllData?.length === 0) {
        usersWithNoGamingDataMap[i] = userFixedDataSetMap[i];
      }
    });

    // merge A and B userIDs
    usersWithNoDataAtAllList = usersWithNoDataAtAllList.concat(Object.keys(usersWithNoGamingDataMap));

    // remove them from normal placement
    Object.keys(userFixedDataSetMap).map(i => {
      if (usersWithNoDataAtAllList.includes(i)) {
        delete userFixedDataSetMap[i];
      }
    });

    // A) ... and move this to the end
    usersWithNoDataAtAllList.forEach(i => {
      if (usersWithNoGamingDataMap[i]) { // user only didnt have gaming data, so we need his real other dataset
        userFixedDataSetMap[i] = usersWithNoGamingDataMap[i];
      } else {
        userFixedDataSetMap[i] = {}; // the had no data at all, so it's a {}
      }
    });

    
    
    // E: lets sort the userFixedDataSetMap to put users without data at the end

    setFullDataSet(userFixedDataSetMap);
    setDataSet(userFixedDataSetMap);
    setDataSummary({
      totalDataPoints,
      totalUsers: rawData.length
    });
    setDataFetching(false);
  }

  const loadDetailedGamerInsights = (gamerId) => {
    setActiveUser(gamerId);

    setActiveGamerData({
      readingsOnChainAddrTxOnCon: dataset[gamerId].readingsOnChainAddrTxOnCon,
      readingsOnChainAddrTxOnConErd: dataset[gamerId].readingsOnChainAddrTxOnConErd,
      readingsDiscordBotUserOnGuildActivity: dataset[gamerId].readingsDiscordBotUserOnGuildActivity,
      readingsTrdPtyWonderHeroGameApi: dataset[gamerId].readingsTrdPtyWonderHeroGameApi,
      socialActivityAllData: dataset[gamerId].socialActivityAllData,
      gamingActivityAllData: dataset[gamerId].gamingActivityAllData,
    });

    onOpen();

    // eslint-disable-next-line no-restricted-globals
    history.pushState({}, 'JavaScript', `/gamer-passport?userId=${gamerId}`);
  }

  const unloadDetailedGamerInsights = () => {
    // eslint-disable-next-line no-restricted-globals
    history.pushState({}, 'JavaScript', '/gamer-passport/preview');

    setActiveUser(null);
    setActiveGamerData(null);
    onClose();
  }

  return (
    <>
      <Box mb="10">
        <Heading>Gamer Passport</Heading>
        <Box mt="2" mb="2">
          A bulk datset of  {' '}
            <Tag mb="1" colorScheme='teal'>{dataSummary.totalDataPoints} Datapoints</Tag> collected from the {' '}
            <Tag mb="1" colorScheme='blue'>Polygon</Tag>, <Tag mb="1"  colorScheme='blue'>BSC</Tag> and <Tag mb="1" colorScheme='blue'>Elrond</Tag> Blockchains and  {' '}
            <Tag mb="1" colorScheme='blue'>Discord</Tag> Social Channels for  {' '}
            <Tag mb="1" colorScheme='green'>{dataSummary.totalUsers} Gamers</Tag> playing the  {' '}
            <Tag mb="1" colorScheme='red'>Wonderhero</Tag>, <Tag mb="1" colorScheme='red'>Cyball</Tag> and <Tag mb="1" colorScheme='red'>Knights of Cathena</Tag> web3 games.
        </Box>

        {(dataset && Object.keys(dataset).length > 0) && <Box mt="10">
          <HStack>
            <Text fontSize="sm">Filter Options</Text>
            <Button variant="outline" colorScheme="teal" size="sm" disabled={activeFilterCode === 'all'} onClick={() => filterData({ filterCode: 'all' })}>All Gamers</Button>
            <Button variant="outline" colorScheme="teal" size="sm" disabled={activeFilterCode === 'elrond'} onClick={() => filterData({ filterCode: 'elrond' })}>Elrond Gamers Only</Button>
            <Button variant="outline" colorScheme="teal" disabled={activeFilterCode === 'evm'} size="sm" onClick={() => filterData({ filterCode: 'evm' })}>EVM Gamers Only</Button>
          </HStack>          
        </Box>}
      </Box>

      {dataset && Object.keys(dataset).length > 0 && <>
        <Text mb="2" fontSize="sm">{Object.keys(dataset).length} Gamers</Text>
        <Wrap>
          {Object.keys(dataset).map(userId => (
            <WrapItem key={userId}>
              <Flex width="300px" minH="150px" borderWidth=".1rem" borderRadius="lg" p="3" direction="column" justify="space-between">
                {dataset[userId]?.gamingActivityAllData?.length > 0 && <Box>
                  <ActivityHeatmap
                    startDate="2022-04-01"
                    endDateIsToday={true}
                    showWeekdayLabels={false}
                    showOutOfRangeDays={false}
                    activityAllData={dataset[userId].gamingActivityAllData} />
                </Box> || <Text fontSize="sm">No Gaming Activity</Text>}

                <IconButton mt="1" variant='outline' w={8} h={8} icon={<ViewIcon />} onClick={() => {
                  loadDetailedGamerInsights(userId);
                }} />
              </Flex>
            </WrapItem>
          ))}
        </Wrap>
      </> || <Wrap>
          {Array(80).fill(0).map((i, idx) =>
            <WrapItem key={idx}>
              <Stack width="300px" minH="150px" borderWidth=".1rem" borderRadius="lg" p="3" direction="column">
                <Skeleton>
                  <Box h="120px" />
                </Skeleton>
              </Stack>
            </WrapItem>
          )}
        </Wrap>}
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={() => {
          unloadDetailedGamerInsights();
        }}
        size="full">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Data for {activeUser}</DrawerHeader>
          <DrawerBody>
            <GamerInsights gamerId={activeUser} gamerData={activeGamerData} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default GamerPassportPreview;
